export default function OnceOffPaymentsScreen() {
  const { publishableKey } = __STRIPE_CHECKOUT_CONFIG__;
  const stripe = Stripe(publishableKey);

  const elements = stripe.elements();
  const cardElement = elements.create('card', {hidePostalCode: true});
  cardElement.mount('#card-element');

  const cardholderName = document.getElementById('cardholder-name');
  const cardButton = document.getElementById('card-button');
  const clientSecret = cardButton.dataset.secret;
  const alertBox = document.getElementById('error-explanation');

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  };

  const loading = $('#loading-message');
  const cardDetails = $('#card-details');

  const toggleSpinner = (toggle) => {
    if (toggle === 'on') {
      loading.removeClass('hidden');
      cardDetails.addClass('hidden');
    } else if (toggle === 'off') {
      loading.addClass('hidden');
      cardDetails.removeClass('hidden');
    }
  };

  const handleErrorDisplay = (error) => {
    alertBox.classList.add('alert');
    alertBox.classList.add('alert-danger');
    alertBox.innerHTML = error.message;
  };

  const submitForm = () => {
    document.getElementById('processed-form').submit();
  };

  cardButton.addEventListener('click', (e) => {
    e.preventDefault();
    toggleSpinner('on');
    stripe.handleCardPayment(
      clientSecret, cardElement, {
        payment_method_data: {
          billing_details: {name: cardholderName.value}
        }
      }
    ).then((result) => {
      if (result.error) {
        toggleSpinner('off');
        handleErrorDisplay(result.error)
      }
      else if (result.paymentIntent.status === 'succeeded'){
        sleep(3000).then(() => {
          submitForm();
        });
      }
      else {
        submitForm();
      }
    });
  });
}
