import { onMount, getCurrentScreenName } from './shared/mount-utils';
import { initImages } from './shared/images';
import { initNavbar } from './shared/navbar';
import { initModals } from './shared/modals';
import { initDataToggle } from './shared/data-toggle';
import TripOverviewScreen from './trip-overview-screen';
import CostsScreen from './costs-screen';
import AcceptanceScreen from './acceptance-screen';
import PartiesScreen from './parties-screen';
import PaymentsScreen from './payments-screen';
import OnceOffPaymentsScreen from './once-off-payments-screen';
import ItineraryScreen from './itinerary-screen';
import DashboardScreen from './dashboard-screen';
import OnlinePaymentScreen from './online-payment-screen';
import DocumentsAccordion from './documents-accordion';

const screens = {
  dashboard: DashboardScreen,
  overview: TripOverviewScreen,
  costs: CostsScreen,
  acceptance: AcceptanceScreen,
  parties: PartiesScreen,
  payments: PaymentsScreen,
  'once-off-payments': OnceOffPaymentsScreen,
  itinerary: ItineraryScreen,
  'online-payment': OnlinePaymentScreen,
  'documents-accordion': DocumentsAccordion,
};

// Executes for all screens
export default function App() {
  onMount(() => {
    initImages();
    initNavbar();
    initModals();
    initDataToggle();

    const screen = screens[getCurrentScreenName()];
    if (screen) {
      screen();
    }
  });
}
